// config.js

const bizIcon = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/sEo5pH9VR7t7IBgwlSUs/media/6730e32d55af5c16d55e9d34.png";
const imageOne = "https://media.istockphoto.com/id/1746617273/photo/a-man-puts-putty-on-a-plasterboard-ceiling-putty-for-the-joints-of-plasterboard-sheets.jpg?s=612x612&w=0&k=20&c=SqP_csGiU7mt5Pe1kL_pov-jne5i7-kFy74ADC_rHqY=";
const imageTwo = "https://media.istockphoto.com/id/1184243507/photo/patching-drywall-walls.jpg?s=612x612&w=0&k=20&c=deG9VWSS1XAR2A_R5kIcjbypd5ic8gbMXbbIPwYLxC4=";
const imageThree = "https://media.istockphoto.com/id/1267627680/photo/construction-worker-uses-to-measure-the-level-in-house.jpg?s=612x612&w=0&k=20&c=tvBBhS6k83JmSNBlcWMGC7MuhKNlWWLr-AvwLPqEipA=";
const imageFour = "https://media.istockphoto.com/id/1329380413/photo/young-home-repair-male-or-worker-busy-with-house-renovation-holds-building-equipment-wears.jpg?s=612x612&w=0&k=20&c=x5wLKhAEg72t8WCnt67yD8nUxlR0FRdyddOgtdE8aRU=";
const imageFive = "https://media.istockphoto.com/id/1514873104/photo/worker-in-hardhat-and-vest-measures-distance-between-future-sockets-with-measuring-reel.jpg?s=612x612&w=0&k=20&c=QjJFiRQsQmdITvFllXYp33RwCP--3e_4YPFCUQmKGeA=";

const socialMediaLink = 'https://cristobaldrywallllc.com/';

export const siteConfig = {
  title: "Cristobal Drywall LLC",
  metaDescription: "Cristobal Drywall LLC",
  theme: {
    primaryColor: "blue-500",
    secondaryColor: "gray-200",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Cristobal Drywall LLC",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Cristobal Drywall LLC",
    headline: "Quality Drywall Service",
    address: "2435 W Highland Ave, Apopka, Florida, 32703, United States",
    description: "Quality drywall services for your lovely home."
  },
  images: {
    business: {
      src: imageOne,
      alt: "Premium Drywall Services",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description:
        "Cristobal Drywall LLC provides top-tier drywall services for residential, commercial, and industrial projects across Florida. From drywall installation to finishing, we deliver quality results tailored to your needs."
    },
    {
      description:
        "Our team of experienced professionals is equipped to manage projects of any size or complexity, ensuring flawless execution and a hassle-free experience for our clients."
    },
    {
      description:
        "Dedicated to excellence, Cristobal Drywall LLC ensures superior craftsmanship, reliability, and exceptional service to enhance and maintain the quality of your property."
    },
  ],
  services: {
    sectionTitle: "Our Services",
    description: "At Cristobal Drywall LLC, we are committed to delivering superior drywall services that enhance the quality and durability of your spaces. With years of expertise in the industry, our skilled team specializes in drywall installation, finishing, and painting to bring your vision to life. We utilize top-grade materials and proven techniques to ensure long-lasting results, tailored to meet your residential, commercial, or industrial needs. We take pride in our dedication to quality, reliability, and customer satisfaction, ensuring every project is completed with precision and care. Let Cristobal Drywall LLC be your trusted partner in creating flawless interiors and exteriors!",
    callouts: [
      {
        name: 'Drywall Service',
        description: "Transform your walls with expert drywall installation that ensures a seamless and durable finish.",
        imageSrc: imageTwo,
        imageAlt: 'Worker installing drywall panels',
      },
      {
        name: 'Finishing Service',
        description: 'Achieve a polished, professional look with our precise drywall finishing services.',
        imageSrc: imageThree,
        imageAlt: 'Smooth and finished drywall surface',
      },
      {
        name: 'Painting Service',
        description: 'Enhance the aesthetic of your space with professional painting services that complement your drywall.',
        imageSrc: imageFour,
        imageAlt: 'Beautifully painted room with flawless walls',
      }
    ],
    otherServices: [
      "Remodeling",
      "Flooring"
    ]
  },  
  about: {
    sectionTitle: "About Us",
    description: "Cristobal Drywall LLC offers top-quality drywall services for residential and commercial clients in Orlando, Sanford, Apopka, Kissimmee, and nearby areas. With over 7 years of experience, we specialize in drywall installation, finishing, repairs, and custom solutions, ensuring every project is completed on time, within budget, and to the highest standards.",
    image: {
      src: imageFive,
      alt: "Cristobal Drywall team in action",
      width: 2432,
      height: 1442
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "2435 W Highland Ave, Apopka, Florida, 32703, United States",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.948430067216!2d-81.51765538469262!3d28.67693758240063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7759f1234567%3A0xabcdef123456789!2s2435%20W%20Highland%20Ave%2C%20Apopka%2C%20FL%2032703%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=2435%20W%20Highland%20Ave,%20Apopka,%20FL%2032703&ll=28.6769,-81.5176&q=2435%20W%20Highland%20Ave' 
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=2435+W+Highland+Ave,+Apopka,+FL+32703&form=HDRSC6&sp=1&pq=2435+w+highland+ave&sc=0-0&qs=n&sk=&cvid=ABCDEF1234567890' 
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/2435+W+Highland+Ave,+Apopka,+FL+32703,+USA/@28.676937,-81.517655,15.26523397a,814.57589625d,35y,0h,0t,0r' 
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/2435+W+Highland+Ave,+Apopka,+FL+32703,+USA/@28.676937,-81.517655,17z' 
      }
    ]
  },  
  contact: {
    sectionTitle: "Schedule a Free Consultation for Your Drywall Needs",
    description: "Looking to enhance your property with high-quality drywall services? Contact Cristobal Drywall LLC today to schedule a free consultation. Let us help you bring your project to life with expert craftsmanship and reliable service.",
    socialLinks: {
      facebook: socialMediaLink,
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      youtube: socialMediaLink,
      tiktok: socialMediaLink
    },
    contactNumbers: ["407-536-0315"],
    logo: bizIcon,
    emailRecipient: "info@cristobaldrywallllc.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
